/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 40px 30px;
  box-shadow: 0px 0 20px rgba(1, 41, 112, 0.1);
  background: #fff;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.3s;
}
.testimonials .testimonial-item .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .profile-pic {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #012970;
    color: #fff;
    border-radius: 50%;
    font-weight: 500;
    font-size: 24px;
  }
}
.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}
.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}
.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}
.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}
.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}
.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}
.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid rgb(0, 111, 224);
}
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: rgb(0, 111, 224);
}
.testimonials .swiper-slide {
  opacity: 0.3;
}
@media (max-width: 1199px) {
  .testimonials .swiper-slide-active {
    opacity: 1;
  }
  .testimonials .swiper-pagination {
    margin-top: 0;
  }
  .testimonials .testimonial-item {
    margin: 40px 100px;
  }
}
@media (min-width: 1200px) {
  .testimonials .swiper-slide-next {
    opacity: 1;
    transform: scale(1.12);
  }
}
