.values .box {
  padding: 30px;
  box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
  text-align: center;
  transition: 0.3s;
  height: 100%;
}
.values .box img {
  padding: 30px 50px;
  transition: 0.5s;
  transform: scale(1.1);
}
.values .box h3 {
  font-size: 24px;
  color: #012970;
  font-weight: 700;
  margin-bottom: 18px;
}
.values .box:hover {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}
.values .box:hover img {
  transform: scale(1);
}

.section_sub_title {
  margin-top: 15px !important;
  line-height: 1.8 !important;
  text-align: center !important;
  width: 100%;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}

.service-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  height: 100%;
  padding: 60px 30px;
  text-align: center;
  transition: 0.3s;
  border-radius: 5px;

  .icon {
    font-size: 36px;
    padding: 20px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
    line-height: 0;
    transition: 0.3s;
    border-radius: 50%;
  }

  h3 {
    color: #012970;
    font-weight: 600;
    font-size: 1.45rem;
  }

  p {
    padding: 0 20px;
    word-break: break-word;
  }

  &.box-blue {
    .icon {
      background: #dbf3fe;
      svg {
        fill: #2db6fa;
      }
    }
  }
  &.box-orange {
    .icon {
      background: #fde3c4;
      svg {
        fill: #f68c09;
      }
    }
  }
  &.box-green {
    .icon {
      background: #cffddf;
      svg {
        fill: #08da4e;
      }
    }
  }
  &.box-red {
    .icon {
      background: #fef7f8;
      svg {
        fill: #e9222c;
      }
    }
  }
  &.box-purple {
    .icon {
      background: #f8e4fd;
      svg {
        fill: #b50edf;
      }
    }
  }
  &.box-pink {
    .icon {
      background: #feecf7;
      svg {
        fill: #f51f9c;
      }
    }
  }
}
