.landing_wrapper {
  .tagline {
    font-size: 18px;
    line-height: 1.8;
  }
  a {
    padding: 15px 20px !important;
    transition: ease-in-out 0.5s;

    &:hover {
      background: #012970;
      color: #fff;
      transition: ease-in-out 0.5s;
    }
  }
}
