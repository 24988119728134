.clients {
  background: transparent;
  .clients-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;

    .slider-wrapper {
      max-height: 200px;
      max-width: 200px;
      width: 200px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
