.slider-wrapper {
  padding: 10px;
  border-radius: 10px;

  .slider-item {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
  }
}

.slider-static {
  display: flex;
  align-items: center;
  justify-content: center;
  .slider-wrapper {
    width: 300;
  }
}
